@keyframes loadAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-element {
    width: 100px;
    height: 100px;
    background-color: red;
    animation: loadAnimation 0.1s infinite linear;
}